import styles from "./main.button.scss";

export default function MainButton({ text, backgroundColor, href }) {
  return (
    <>
      {href ? (
        <a
          href={href}
          className={"main_button"}
          style={{
            backgroundColor,
          }}
        >
          {text}
        </a>
      ) : (
        <button
          className={"main_button"}
          style={{
            backgroundColor,
          }}
        >
          {text}
        </button>
      )}

      <style jsx>{styles}</style>
    </>
  );
}
