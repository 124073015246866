import MainHeader from "../../headers/main/main.header";
import MainFooter from "../../footers/main/main.footer";

export default function MainLayout({ children, categories }) {
  return (
    <main role="document" tabIndex={0}>
      <MainHeader categories={categories} />
      <section>{children}</section>
      <MainFooter />
    </main>
  );
}
