import { axiosInstanceV1 } from "./axiosInstance";
import { parseResponse } from "./helper";

export async function getCategoriesByKey_req(
  cookies = {},
  viewCount = 10,
  page = 1
) {
  return parseResponse(
    axiosInstanceV1.get("/GetCategoryListByKey", {
      params: {
        viewCount,
        page,
      },
    })
  );
}

export async function getCategories_req(
  cookies = {},
  viewCount = 10,
  page = 1
) {
  return parseResponse(
    axiosInstanceV1.get("/GetCategoryList", {
      params: {
        viewCount,
        page,
      },
    })
  );
}

export async function getItems_req(
  cookies = {},
  key,
  viewCount = 50,
  page = 1
) {
  return parseResponse(
    axiosInstanceV1.get("/GetItemList", {
      params: {
        viewCount,
        page,
        key,
      },
    })
  );
}
