export function makePercentsFromRate(rate) {
  const percentArray = new Array(5).fill(0);

  let totalPercent = rate * 100;
  let percents = 0;

  let index = 0;
  while (percents + 100 < totalPercent) {
    percentArray[index] = 100;
    totalPercent -= 100;
    index++;
  }

  percentArray[index] = totalPercent;
  return percentArray;
}
