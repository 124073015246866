import Link from "next/link";

import styles from "./game.item.scss";

import EighteenPlusImg from "../../../assets/images/icons/eighteen-plus.svg";
import MainButton from "../../buttons/main/main.button";

import { makePercentsFromRate } from "../../../helpers/calculations.helper";
import Image from "next/image";

export default function GameItem({ isMobile, item }) {
  const percentArray = makePercentsFromRate(item.rate);

  return (
    <>
      <div className="game_item">
        <div className="place_wrapper column">
          <span className={"place"}>{item.rank}</span>
        </div>
        <div className="game_data_info">
          <div className="column image_wrapper">
            <Link
              href={`/games/${item.title.replace(" ", "-")}-${item.id}`}
              as={`/games/${item.title.replace(" ", "-")}-${item.id}`}
            >
              <Image
                className="game_img"
                width="160"
                height="90"
                src={
                  process.env.NEXT_PUBLIC_API_BASE_URL_MEDIA_V1 + item.mediaID
                }
                alt={item.title}
              />
            </Link>
          </div>
          <div className="game_info">
            <Link
              className={"game_name"}
              href={`/games/${item.title.replace(" ", "-")}-${item.id}`}
              as={`/games/${item.title.replace(" ", "-")}-${item.id}`}
            >
              {item.title}
            </Link>
            <div className="rate_wrapper">
              <span className={"rate"}>
                Players&apos; rate ({item.rateCount})
              </span>
              <div className="bottom_score">
                <div className="stars">
                  {percentArray && percentArray.length ? (
                    percentArray.map((percent, index) => (
                      <div key={percent + "-" + index} className="star">
                        <div
                          className="fill"
                          style={{ width: percent + "%" }}
                        />
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
                <span className={"score"}>{item.rate}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="main_content">
          <div className="bonus_info">
            <div dangerouslySetInnerHTML={{ __html: item.shortDescription }} />
          </div>
          <div className="features_info">
            <div dangerouslySetInnerHTML={{ __html: item.longDescription }} />
          </div>
        </div>
        <div className="actions_info">
          <MainButton
            text={item.btnText}
            backgroundColor={item.btnCollor}
            href={item.url}
          />
          <div className="actions_wrapper">
            {/*<a href="">Full T&C rules</a>*/}
            <Image
              className="plus_img"
              width="38"
              height="38"
              src={EighteenPlusImg}
              alt="logo"
            />
          </div>
        </div>
      </div>
      <style jsx>{styles}</style>
    </>
  );
}
