import axios from "axios";

export const baseURLV1 = process.env.NEXT_PUBLIC_API_BASE_URL_V1;

export const axiosInstanceV1 = axios.create({
  baseURL: baseURLV1,
  timeout: 60000,
});

const successResponse = (response) => response;

const errorResponse = (error) => {
  return Promise.reject(error);
};

const setHeaders = (reqConfig) => reqConfig;

axiosInstanceV1.interceptors.request.use(
  async (config) => config,
  (error) => Promise.reject(error)
);

axiosInstanceV1.interceptors.request.use(setHeaders);
axiosInstanceV1.interceptors.response.use(successResponse, errorResponse);
