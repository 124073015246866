export const getDeviceData = (req) => {
  const deviceData = {
    isMobile: false,
    isAndroid: false,
    isIOS: false,
  };

  if (!req || !req.headers) {
    return deviceData;
  }

  const userAgentData = (req.headers["user-agent"] || "")
    .toLowerCase()
    .split(" ");

  if (userAgentData.indexOf("iphone") >= 0) {
    deviceData.isIOS = true;
    deviceData.isMobile = true;
  }
  if (userAgentData.indexOf("android") >= 0) {
    deviceData.isAndroid = true;
    deviceData.isMobile = true;
  }

  return deviceData;
};
