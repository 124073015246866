import React from "react";
import Link from "next/link";
import Image from "next/image";

import styles from "./main.sidebar.scss";

import CloseIcon from "../../assets/images/icons/close.svg";

export default function MainSidebar({ categories, onClose }) {
  return (
    <>
      <div className="main_sidebar show_mobile">
        <Image
          className="close"
          src={CloseIcon}
          alt="close"
          width={24}
          height={24}
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
        />
        <ul>
          {categories.map((category) => (
            <li key={category.title}>
              <Link href={"/" + category.key} as={"/" + category.key}>
                {category.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <style jsx>{styles}</style>
    </>
  );
}
