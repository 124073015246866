import styles from "./main.footer.scss";

export default function MainFooter() {
  return (
    <>
      <footer></footer>
      <style jsx>{styles}</style>
    </>
  );
}
