import Link from "next/link";

import MainLogoImg from "../../../assets/images/logos/main-logo.svg";
import BurgerImg from "../../../assets/images/icons/burger.svg";

import styles from "./main.header.scss";
import Image from "next/image";
import MainSidebar from "../../sidebars/main.sidebar";
import { useState } from "react";

export default function MainHeader({ categories }) {
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <>
      <header>
        <div className="inner_wrapper">
          <Link href="/" as="/">
            <Image
              className="logo_img"
              width="122"
              height="84"
              src={MainLogoImg}
              alt="logo"
            />
          </Link>
          <div className={"right_side"}>
            {categories && categories.length ? (
              <ul className={"show_desktop"}>
                {categories.map((category) => (
                  <li key={category.title}>
                    <Link href={"/" + category.key} as={"/" + category.key}>
                      {category.title}
                    </Link>
                  </li>
                ))}
              </ul>
            ) : (
              <></>
            )}

            <Image
              className="burger_img show_mobile"
              width="14"
              height="15"
              src={BurgerImg}
              alt="menu"
              onClick={() => setShowSidebar(true)}
            />
          </div>
        </div>
        {showSidebar && categories && categories.length ? (
          <MainSidebar
            categories={categories}
            onClose={() => setShowSidebar(false)}
          />
        ) : (
          <></>
        )}
      </header>
      <style jsx global>
        {styles}
      </style>
    </>
  );
}
